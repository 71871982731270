<template>
  <div class="teaching_home">
    <router-view class="manage-router-view"></router-view>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="less" scoped>
  .teaching_home {
    margin: 0 auto;
    padding: 20px 25px;

    h2 {
      margin-bottom: 9px;
      line-height: 77px;

      font-size: 28px;
    }

    @media screen and (min-width: 1250px) {
      padding: 25px 30px;
    }
  }
</style>